
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import VueRecaptcha from 'vue-recaptcha';
import InfoSide from '@/components/InfoSide.vue';
import AuthMethods from '@/store/auth/methods/auth.methods';
import CountriesMethods from '@/store/countries/methods/countries.methods';
import { auth, countries } from '@/store/namespaces';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { User, UserLogin } from '../../interfaces/user.interface';
import Loader from '@/components/utilities/Loader.vue';
import { cookie } from '@/utils/cookies';
import CryptoJS from 'crypto-js';
import { showToast } from '@/utils/global-functions';
import store from '@/store';

@Component({
    components: {
        SvgIcon,
        VueRecaptcha,
        InfoSide,
        Loader,
    },
    mixins: [validationMixin],
    validations: {
        user: {
            email: { required },
            password: { required },
        },
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: 'Descripción de la página',
                },
            ],
        };
    },
})
export default class Login extends Vue {
    registerButton = {
        name: 'Registrarme',
        text: '¿Aún no tienes cuenta?',
        level: 1,
        redirect: '/registro',
    };
    user: UserLogin = {
        email: '',
        password: '',
        captcha: undefined,
    };
    $refs: any = {};
    recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
    captchaRendered = false;
    rememberUserCheck = false;
    showPassword = false;

    created() {
        this.$socket.client.connect();
    }

    mounted() {
        this.user.email = '';
        this.user.password = '';
        this.isUserAlreadyRemembered();
    }

    isUserAlreadyRemembered() {
        let remembered = cookie.isCookieSessionActive();
        if (remembered) {
            this.user.email = remembered;
            this.rememberUserCheck = true;
        }
    }

    verifyMethod(id: string) {
        this.user.captcha = id;
    }

    expiredMethod(el: any) {
        this.$refs.recaptcha.reset();
    }

    renderMethod(el: any) {
        this.captchaRendered = true;
    }

    errorMethod(el: any) {
        // showToast('Error verificando el reCaptcha', 'error')
    }

    isInAmerica(country: string) {
        const americaCountries = [
            'AR',
            'BO',
            'BR',
            'CL',
            'CO',
            'CR',
            'CU',
            'DO',
            'EC',
            'SV',
            'GT',
            'HN',
            'MX',
            'NI',
            'PA',
            'PY',
            'PE',
            'PR',
            'UY',
            'VE',
            'CA',
            'US',
            'AG',
            'BS',
            'BB',
            'BZ',
            'DM',
            'GD',
            'HT',
            'JM',
            'KN',
            'LC',
            'VC',
            'TT',
            'SR',
            'GY',
        ];
        return americaCountries.includes(country);
    }

    get baseUrl() {
        const hostname = window.location.hostname;
        if (hostname.includes('localhost')) {
            return 'localhost:8080';
        }
        const env = hostname.split('.')[0]; // Extrae el subdominio (dev, test, etc.)
        return `${env}`;
    }

    get latamUrl() {
        const hostname = window.location.hostname;
        return hostname.includes('localhost')
            ? `http://${this.baseUrl}/tablero`
            : `https://${this.baseUrl}.com/tablero`;
    }

    get europeUrl() {
        const hostname = window.location.hostname;
        return hostname.includes('localhost')
            ? `http://${this.baseUrl}/tablero`
            : `https://${this.baseUrl}.es/tablero`;
    }

    goToLanding() {
        window.location.hostname.includes('.es')
            ? (window.location.href = 'https://www.bithonor.es')
            : (window.location.href = 'https://www.bithonor.com');
    }

    async submit() {
        // try{
        this.$v.$touch();
        if (!this.$v.$invalid && this.user.captcha !== undefined) {
            //
            this.$refs.startLoader.showLoader();
            let message = await this.login(this.user);
            if (message.successMessage) {
                this.$socket.client.connect();
                if (this.rememberUserCheck) {
                    cookie.setSessionCookie(this.userData.email_user);
                } else cookie.removeSessionCookie();
                // DESCOMENTAR CUANDO SE HAGA EL FLUJO DE MIGRADOS
                // if (this.userData.id_migrated && !this.userData.completed_information_migrated)
                //     this.$router.push({name: 'LevelOne'}).catch();
                // else this.$router.push({name: 'Dashboard',params:{login:true}}).catch();

                this.$router
                    .push({ name: 'Dashboard', params: { login: true } })
                    .catch();

                // if (this.isInAmerica(this.userData.iso_code_resid_country))
                // {
                //     window.location.href = this.latamUrl;
                // } else {
                //     window.location.href = this.europeUrl;
                // }
            } else if (!message.noResponse) {
                if (message.redirect) {
                    this.$router
                        .push({
                            name: message.redirect,
                            params: { email: this.user.email },
                        })
                        .catch();
                } else {
                    showToast(message.errorMessage, 'error');
                    await this.$refs.recaptcha.reset();
                }
            }
        }
        if (this.user.captcha === undefined)
            showToast('Confirma que no eres un robot', 'error');
        this.$refs.startLoader.hideLoader();
        this.$refs.recaptcha.reset();
        this.user.captcha = undefined;
        // }catch(e){
        //     if (this.$refs.startLoader) this.$refs.startLoader.hideLoader();
        //     showToast('Ha ocurrido un error iniciando tu sesión','error');
        // }
    }

    rememberUser() {
        this.rememberUserCheck = !this.rememberUserCheck;
    }

    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @auth.Action(AuthMethods.actions.LOGIN)
    login!: (data: UserLogin) => Promise<{
        errorMessage?: string;
        successMessage?: string;
        redirect?: string;
        noResponse?: boolean;
    }>;
}
